import * as React from "react";
import styled from "styled-components";
import BoldContentful from "../utils/BoldContentful";

const Container = styled.div`
  color: ${(p) => p.color};
  border-radius: 4px;
  display: inline-block;
  font-weight: 300;
  font-size: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin: 0px 8px;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const Textcontainer = styled.h2`
    font-size: 38px;
  @media (max-width: 900px) {
    font-size: 24px;
    width: 65%;
    text-align: center;
  }
`;

const Titlerich = (props) => {
  return (
    <Container color={props.colortext}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.62 0.899414H0.501953V7.95823H7.62V0.899414Z"
          fill={props.color}
        />
        <path
          d="M16.1611 0.899414H9.04297V7.95823H16.1611V0.899414Z"
          fill={props.color}
        />
        <path
          d="M24.5009 0.899414H17.3828V7.95823H24.5009V0.899414Z"
          fill={props.color}
        />
        <path
          d="M16.1611 9.37012H9.04297V16.4289H16.1611V9.37012Z"
          fill={props.color}
        />
        <path
          d="M24.5009 9.37012H17.3828V16.4289H24.5009V9.37012Z"
          fill={props.color}
        />
        <path
          d="M24.5009 17.8408H17.3828V24.8996H24.5009V17.8408Z"
          fill={props.color}
        />
      </svg>
      <Textcontainer>
        {BoldContentful(props.container, props.colorbold)}
      </Textcontainer>

      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.38 0.899414H24.498V7.95823H17.38V0.899414Z"
          fill={props.color}
        />
        <path
          d="M8.83893 0.899414H15.957V7.95823H8.83893V0.899414Z"
          fill={props.color}
        />
        <path
          d="M0.499146 0.899414H7.61719V7.95823H0.499146V0.899414Z"
          fill={props.color}
        />
        <path
          d="M8.83893 9.37012H15.957V16.4289H8.83893V9.37012Z"
          fill={props.color}
        />
        <path
          d="M0.499146 9.37012H7.61719V16.4289H0.499146V9.37012Z"
          fill={props.color}
        />
        <path
          d="M0.499146 17.8408H7.61719V24.8996H0.499146V17.8408Z"
          fill={props.color}
        />
      </svg>
    </Container>
  );
};

export default Titlerich;
