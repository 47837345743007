import * as React from "react";
import styled from "styled-components";
import {Link} from "gatsby";

const Container = styled.div`
padding:12px 31px;
background-color:var(--celeste);
color:var(--blanco);
border-radius:4px;
display: inline-block;
font-weight: 600;
z-index:${p=>p.z || 1} ;
a{
   color:var(--blanco);
   text-decoration: none; 
}

:hover{
    background-color:#47aecb; 
    cursor: pointer;
}

`

const Button = ({ children , enlace, zindex}) => {

    return(
     
        <Container z = {zindex} >
           <Link to ={enlace}>
            {children}
           
            </Link>
    </Container>
    
      
      
    )
    
    }
    
    export default Button;