import * as React from "react";
import styled from "styled-components";
import BoldContentful from "../../../utils/BoldContentful";

const Container = styled.div`
width:100%;
display:flex;
//height:650px;
justify-content:center;
@media (max-width: 900px) {
    height:auto;
}

`

const Subcontainer = styled.div `
width: 90%;
max-width: 1440px;
display: flex;
justify-content:center;
flex-direction: row ;

@media (max-width: 900px) {
  width: calc(100% - 60px);
  flex-direction: column-reverse;
}
`
const Leftcontainer = styled.div`
width: 50%;
display: flex;
flex-direction: column;
padding:0px 5%; 
@media (max-width: 900px) {
    width: 100%; 
    padding:0px 0%; 
}
`

const Rightcontainer = styled.div`
width: 50%;

@media (max-width: 900px) {
    width: 100%; 
}

`

const Titlecontainer = styled.div`

color:${p=>p.co};
font-size:38px;
font-weight:700;
margin-bottom:16px;


`

const Textcontainer = styled.div`

color: var(--gris);
font-size:16px;
font-weight:400;
margin-bottom:16px;

`






const Historia = ({titulo , texto , children,fontcb ,fontc}) => {


    return(
     
        <Container  >
            <Subcontainer>
<Leftcontainer>
<Titlecontainer co={fontc}>
{BoldContentful(titulo,fontcb)}
</Titlecontainer>
<Textcontainer>
{BoldContentful(texto,"var(--gris)")}
</Textcontainer>

</Leftcontainer>
<Rightcontainer>{children}</Rightcontainer>
               
            </Subcontainer>
    </Container>
    )
    }
    
    export default Historia;